import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useHeader from 'hooks/useHeader';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from '../../../assets/scss/components/CoachingCard.module.scss';
import imagerPlaceHolder from '../../../assets/images/placeholder-image.png';
import useGeneral from 'hooks/useGeneral';
import { REGISTER_TYPE } from 'constants/login';
import {
  COACHING_DETAIL_PAGE,
  COACHING_MODULE_DETAILS_PAGE,
  COACHING_PAYMENT_FIRST_STEP,
  CLIPS_DETAIL_PAGE,
  CLIPS_MODULE_DETAILS_PAGE,
} from '../../../constants/routes';
import useIsAuthenticated from 'hooks/useIsAuthenticated';

function TopicCard(props) {
  const {
    wrapper,
    leftButton,
    rightButton,
    coachingDescription,
    coachingTitle,
    detailsWrapper,
    costTag,
    freeTag,
  } = convertKeysToCamelCase(style);
  const { image, title, shortDescription, id, price, isPaidFor, type, canBePurchased, eLearning } = props;
  const { handleRedirect } = useGeneral();
  const { handleModalOpen } = useHeader();
  const [imageState, setImageState] = useState(image);
  const { isUserLoggedIn } = useIsAuthenticated();
  const { t } = useTranslation();

  const topicCardRedirect = () => {
    switch (type) {
      case 'clips':
        return isPaidFor && CLIPS_MODULE_DETAILS_PAGE.replace(':moduleId', id);
      case 'coachings':
        return isPaidFor
          ? COACHING_MODULE_DETAILS_PAGE.replace(':moduleId', id)
          : COACHING_DETAIL_PAGE.replace(':coachId', id);
      default:
        return isPaidFor
          ? COACHING_MODULE_DETAILS_PAGE.replace(':moduleId', id)
          : COACHING_DETAIL_PAGE.replace(':coachId', id);
    }
  };

  const redirectButtonText = () => {
    switch (type) {
      case 'clips':
        return isPaidFor ? t('coachings.start.now') : t('general.buttons.register_now');
      case 'coachings':
        return isPaidFor ? t('coachings.start.now') : t('coachings.list.details');
      default:
        return isPaidFor ? t('coachings.start.now') : t('coachings.list.details');
    }
  };

  const handleRedirectOnClick = () => {
    if (!isUserLoggedIn && type === 'clips') {
      handleModalOpen(true, REGISTER_TYPE, true);
      return;
    }
    handleRedirect(topicCardRedirect());
  };

  return (
    <div className={wrapper}>
      <figure>
        <img
          alt="img"
          src={imageState}
          onError={() => {
            setImageState(imagerPlaceHolder);
          }}
        />
        {!eLearning && <span className={type == 'clips' ? freeTag : costTag}>{t(`${type}.list.tag`)}</span>}
      </figure>
      <div className={detailsWrapper}>
        <span>
          <h4 className={coachingTitle}>{title}</h4>
          {!isPaidFor && price && (
            <h4>
              {price}
              {t('euroNetto')}
            </h4>
          )}
          <p className={`${coachingDescription} copy`}>{shortDescription}</p>
        </span>
        {!eLearning ? (
          <span>
            <button className={`primary ${leftButton}`} onClick={handleRedirectOnClick}>
              {redirectButtonText()}
            </button>

            {isUserLoggedIn && !isPaidFor && canBePurchased === 1 && (
              <button
                className={`secondary ${rightButton}`}
                onClick={() => {
                  handleRedirect(COACHING_PAYMENT_FIRST_STEP.replace(':coachId', id));
                }}
              >
                {t('coachings.list.purchase')}
              </button>
            )}
          </span>
        ) : isPaidFor ? (
          <span>
            <button className={`primary ${leftButton}`} onClick={handleRedirectOnClick}>
              {t('coachings.start.now')}
            </button>
          </span>
        ) : null}
      </div>
    </div>
  );
}

export default TopicCard;
