import React from 'react';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/VideoTabFaqs.module.scss';

function VideoTabDownloads(props) {
  const { faqs } = props;
  const { wrapper, itemWrapper, itemContent, itemContentTitle } = convertKeysToCamelCase(style);

  return (
    <div className={wrapper}>
      {faqs.map((faq) => {
        return (
          <div key={faq.id} className={itemWrapper}>
            <h4 className={itemContentTitle}> {faq.question}</h4>
            <span className={itemContent}> {faq.answer}</span>
          </div>
        );
      })}
    </div>
  );
}

export default VideoTabDownloads;
