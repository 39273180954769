import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  DASHBOARD_PAGE,
  ERROR_PAGE,
  EXPERT_TALKS_PAGE,
  EXTRA_PAGE,
  FOR_NURSES_PAGE,
  FOR_PARTNERS_PAGE,
  HOME_PAGE,
  MY_PROFILE_PAGE,
  RESET_PASSWORD_PAGE,
  VIDEO_PAGE,
  TRAINING_PAGE,
  OLD_TRAINING_PAGE,
  VIDEO_PAGE_EXPERT_TALKS,
  COACHING_LESSONS_PAGE,
  COACHING_PAGE,
  COACHING_DETAIL_PAGE,
  COACHING_MODULE_PAGE,
  COACHING_MODULE_DETAILS_PAGE,
  COACHING_PAYMENT_FIRST_STEP,
  COACHING_PAYMENT_SECOND_STEP,
  COACHING_PAYMENT_THIRD_STEP,
  COACHING_PAYMENT_FOURTH_STEP,
  REGISTRATION_CONFIRMATION_PAGE,
  REGISTRATION_CONFIRMED_PAGE,
  QUIZ_PAGE,
  NEWSLETTER_CONFIRM_PAGE,
  NEWSLETTER_CONFIRMED_PAGE,
  MODULE_PAYMENT_FIRST_STEP,
  MODULE_PAYMENT_SECOND_STEP,
  MODULE_PAYMENT_THIRD_STEP,
  MODULE_PAYMENT_FOURTH_STEP,
  FULL_CONTACT_PAGE,
  COACHING_MODULE_ENDED_PAGE,
  CLIPS_PAGE,
  CLIPS_LESSONS_PAGE,
  CLIPS_MODULE_PAGE,
  CLIPS_MODULE_DETAILS_PAGE,
  CLIPS_MODULE_ENDED_PAGE,
  CLIPS_DETAIL_PAGE,
  PARTNERS_PAGE,
  PARTNER_PAGE_SINGULAR,
  PARTNERS_PAGE_REDIRECT,
  REGISTER_PAGE,
  REGISTER_PAGE_CODE,
  ONCO_COACH_2025,
  ONCO_COACH_2025_V2,
} from 'constants/routes';
import PrivateRoute from './PrivateRoute';
import DashboardPage from './pages/DashboardPage';
import ProfilePage from './pages/ProfilePage';
import ForNursesPage from './pages/ForNursesPage';
import ErrorPage from './pages/ErrorPage';
import HomePage from './pages/HomePage';
import TrainingPage from './pages/TrainingPage';
import ExpertsTalksPage from './pages/ExpertsTalksPage';
import PartnersPage from './pages/PartnersPage';
import VideoPage from './pages/VideoPage';
import ForPartnersPage from './pages/ForPartnersPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ExtraPage from './pages/ExtraPage';
import useAuth from '../hooks/useAuth';
import ReactLoader from './ReactLoader';
import PublicRoute from './PublicRoute';
import CoachingPage from './pages/CoachingPage';
import CoachingModulePage from './pages/CoachingModulePage';
import CoachingLessonPage from './pages/CoachingLessonPage';
import CoachingPaymentFirstPage from './pages/CoachingPaymentFirstPage';
import CoachingDetailsPage from './pages/CoachingDetailsPage';
import CoachingPaymentSecondPage from './pages/CoachingPaymentSecondPage';
import CoachingPaymentThirdPage from './pages/CoachingPaymentThirdPage';
import CoachingPaymentFourthPage from './pages/CoachingPaymentFourthPage';
import ClipsPage from './pages/clips/ClipsPage';
import RegisterPage from './pages/RegisterPage';
import RegistrationConfirmPage from './pages/RegistrationConfirmPage';
import RegistrationConfirmedPage from './pages/RegistrationConfirmedPage';
import useGoogleStatistics from 'hooks/useGoogleStatistics';
import QuizPage from './pages/QuizPage';
import NewsletterConfirmPage from './pages/NewsletterConfirmPage';
import NewsletterConfirmedPage from './pages/NewsletterConfirmedPage';
import PartnerDetailPage from './pages/PartnerDetailPage';
import ContactPage from './pages/ContactPage';
import CoachingModuleEnded from './pages/coachings/CoachingModuleEnded';
import useIsAuthenticated from '../hooks/useIsAuthenticated';
import { OncoCoach2025 } from './pages/OncoCoach2025';

function Routes() {
  const { isLoading } = useSelector((store) => store.defaultReducer);
  useAuth();
  useGoogleStatistics();

  const { isUserLoggedIn } = useIsAuthenticated();

  if (isUserLoggedIn) {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectTo = searchParams.get('redirectTo');
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }

  return (
    <>
      <Switch>
        <Route path={ERROR_PAGE} component={ErrorPage} />
        <Route path={EXTRA_PAGE} component={ExtraPage} />
        <Route path={NEWSLETTER_CONFIRM_PAGE} component={NewsletterConfirmPage} />
        <Route path={NEWSLETTER_CONFIRMED_PAGE} component={NewsletterConfirmedPage} />
        <Route exact path={REGISTER_PAGE} component={RegisterPage} />
        <Route exact path={REGISTER_PAGE_CODE} component={RegisterPage} />

        <PublicRoute path={FOR_NURSES_PAGE} component={ForNursesPage} />
        <PublicRoute path={FOR_PARTNERS_PAGE} component={ForPartnersPage} />
        <PublicRoute path={RESET_PASSWORD_PAGE} component={ResetPasswordPage} />
        <Route path={HOME_PAGE} component={HomePage} exact={true} />
        <PublicRoute path={REGISTRATION_CONFIRMATION_PAGE} component={RegistrationConfirmPage} />
        <PublicRoute path={REGISTRATION_CONFIRMED_PAGE} component={RegistrationConfirmedPage} />

        <PrivateRoute path={DASHBOARD_PAGE} component={DashboardPage} />
        <PrivateRoute path={VIDEO_PAGE} component={VideoPage} redirectRoute={TRAINING_PAGE} />
        <PrivateRoute path={COACHING_LESSONS_PAGE} exact={true} component={CoachingLessonPage} />
        <PrivateRoute path={COACHING_MODULE_PAGE} exact={true} component={CoachingModulePage} />
        <PrivateRoute path={COACHING_MODULE_DETAILS_PAGE} exact={true} component={CoachingModulePage} />
        <PrivateRoute path={COACHING_MODULE_ENDED_PAGE} exact={true} component={CoachingModuleEnded} />

        <Route path={COACHING_DETAIL_PAGE} component={CoachingDetailsPage} />
        <PrivateRoute path={COACHING_PAYMENT_FIRST_STEP} component={CoachingPaymentFirstPage} />
        <PrivateRoute path={COACHING_PAYMENT_SECOND_STEP} component={CoachingPaymentSecondPage} />
        <PrivateRoute path={COACHING_PAYMENT_THIRD_STEP} component={CoachingPaymentThirdPage} />
        <PrivateRoute path={COACHING_PAYMENT_FOURTH_STEP} component={CoachingPaymentFourthPage} />

        <PrivateRoute path={MODULE_PAYMENT_FIRST_STEP} component={CoachingPaymentFirstPage} />
        <PrivateRoute path={MODULE_PAYMENT_SECOND_STEP} component={CoachingPaymentSecondPage} />
        <PrivateRoute path={MODULE_PAYMENT_THIRD_STEP} component={CoachingPaymentThirdPage} />
        <PrivateRoute path={MODULE_PAYMENT_FOURTH_STEP} component={CoachingPaymentFourthPage} />

        <Route path={COACHING_PAGE} component={CoachingPage} />

        <PrivateRoute path={CLIPS_LESSONS_PAGE} type={'clips'} exact={true} component={CoachingLessonPage} />
        <PrivateRoute path={CLIPS_MODULE_PAGE} type={'clips'} exact={true} component={CoachingModulePage} />
        <PrivateRoute path={CLIPS_MODULE_DETAILS_PAGE} type={'clips'} exact={true} component={CoachingModulePage} />
        <PrivateRoute path={CLIPS_MODULE_ENDED_PAGE} type={'clips'} exact={true} component={CoachingModuleEnded} />

        <Route path={CLIPS_DETAIL_PAGE} render={(props) => <CoachingDetailsPage type={'clips'} {...props} />} />

        <Route path={CLIPS_PAGE} component={ClipsPage} />

        <PrivateRoute path={QUIZ_PAGE} component={QuizPage} />

        <PrivateRoute path={VIDEO_PAGE_EXPERT_TALKS} component={VideoPage} redirectRoute={TRAINING_PAGE} />
        <Route path={TRAINING_PAGE} component={TrainingPage} />
        <Redirect from={OLD_TRAINING_PAGE} to={TRAINING_PAGE} />
        {/* <Route path={EXPERT_TALKS_PAGE} component={ExpertsTalksPage} /> */}
        <PrivateRoute path={MY_PROFILE_PAGE} component={ProfilePage} />

        <Route path={PARTNERS_PAGE_REDIRECT} render={() => <Redirect to={PARTNERS_PAGE} />} />
        <Route path={PARTNERS_PAGE} exact={true} component={PartnersPage} />
        <Route path={PARTNER_PAGE_SINGULAR} component={PartnerDetailPage} />

        <Route path={FULL_CONTACT_PAGE} component={ContactPage} />
        <Route path={ONCO_COACH_2025} component={OncoCoach2025} />
        <Route path={ONCO_COACH_2025_V2} component={OncoCoach2025} />

        <Redirect to={ERROR_PAGE} />
      </Switch>

      {isLoading && <ReactLoader />}
    </>
  );
}

export default Routes;
