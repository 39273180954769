import React from 'react';
import { useTranslation } from 'react-i18next';

import WelcomeCard from '../WelcomeCard';
import LastViewedCard from '../LastViewedCard';
import VideoCarouselCard from '../VideoCarouselCard';
import StayInformedCard from '../StayInformedCard';
import ContactFormCard from '../ContactFormCard';
import useDashboardPage from '../../hooks/useDashboardPage';
import { MEETING_TYPE_ZOOM } from '../../constants/general';
import CoachingCards from 'components/CoachingCards';
import style from 'assets/scss/components/pages/DashboardPage.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';

function DashboardPage() {
  const { coachingSection } = convertKeysToCamelCase(style);
  const { t } = useTranslation();
  const {
    interestedInVideos,
    previouslyWatchedVideos,
    currentVideo,
    purchasedCoachings,
    purchasedClips,
  } = useDashboardPage();

  return (
    <main className="main">
      <section className="small max-width">
        <WelcomeCard />
      </section>
      <section className={`small max-width ${currentVideo?.type === MEETING_TYPE_ZOOM && 'no-padding'}`}>
        <LastViewedCard video={currentVideo} />
      </section>
      <section className="small-padding-left max-width">
        <div className="carouse-section">
          {purchasedCoachings && purchasedCoachings.length > 0 && (
            <div className={coachingSection}>
              <h4>Ihre E-Learnings</h4>
              <CoachingCards usedOnDashboard={true} coachings={purchasedCoachings} />
            </div>
          )}
          {purchasedClips && purchasedClips.length > 0 && (
            <div className={coachingSection}>
              <h4>Ihre Clips</h4>
              <CoachingCards usedOnDashboard={true} coachings={purchasedClips} type={'clips'} />
            </div>
          )}
          <VideoCarouselCard
            usedOnDashboard={true}
            title={t('dashboard.already.watched.card.title')}
            items={previouslyWatchedVideos}
          />
          <VideoCarouselCard
            usedOnDashboard={true}
            title={t('dashboard.also.interested.card.title')}
            items={interestedInVideos}
          />
        </div>
      </section>

      <section className="grey">
        <StayInformedCard />
      </section>

      <section className="secondary">
        <ContactFormCard />
      </section>
    </main>
  );
}

export default DashboardPage;
