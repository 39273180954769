import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { VIDEO_TAB_COURSE_OVERVIEW, VIDEO_TAB_DESCRIPTION, E_LEARNING_TAB_COURSE_OVERVIEW } from '../constants/video';

function useCoachingVideoTabs(isLesson, eLearning) {
  const [selectedIndex, setSelectedIndex] = useState(
    eLearning ? E_LEARNING_TAB_COURSE_OVERVIEW : isLesson ? VIDEO_TAB_DESCRIPTION : VIDEO_TAB_COURSE_OVERVIEW,
  );

  useEffect(() => {
    setSelectedIndex(
      eLearning ? E_LEARNING_TAB_COURSE_OVERVIEW : isLesson ? VIDEO_TAB_DESCRIPTION : VIDEO_TAB_COURSE_OVERVIEW,
    );
  }, [isLesson, eLearning]);

  const { video } = useSelector((store) => store.defaultReducer);

  return { video, selectedIndex, setSelectedIndex };
}

export default useCoachingVideoTabs;
