import React from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import {
  COACHING_LESSON_TABS,
  E_LEARNING_MODULE_TABS,
  COACHING_MODULE_TABS,
  VIDEO_TAB_COURSE_OVERVIEW,
  VIDEO_TAB_DESCRIPTION,
  VIDEO_TAB_MATERIAL,
  VIDEO_TAB_NOTES,
  E_LEARNING_TAB_FAQ,
  E_LEARNING_TAB_COURSE_OVERVIEW,
} from '../constants/video';
import VideoTabNotes from './VideoTabNotes';
import useCoachingVideoTabs from '../hooks/useCoachingVideoTabs';
import style from '../assets/scss/components/VideoTabs.module.scss';
import VideoCourseDescription from './VideoCourseDescription';
import VideoTabDownloads from './VideoTabDownloads';
import VideoTabFaqs from './VideoTabFaqs';

function CoachingVideoTabs(props) {
  const { isLesson, isWholeCoachingPurchased, type, eLearning, faqs } = props;
  const { t } = useTranslation();
  const { wrapper, tabs, tabsActive } = convertKeysToCamelCase(style);
  const { video, selectedIndex, setSelectedIndex } = useCoachingVideoTabs(isLesson, eLearning);
  const { longDescription, coursePlan, downloads, description } = video;
  const items = eLearning
    ? faqs && faqs.length > 0
      ? E_LEARNING_MODULE_TABS
      : E_LEARNING_MODULE_TABS.splice(-1)
    : isLesson
    ? COACHING_LESSON_TABS
    : COACHING_MODULE_TABS;
  const overview = [E_LEARNING_TAB_COURSE_OVERVIEW, VIDEO_TAB_DESCRIPTION, VIDEO_TAB_COURSE_OVERVIEW];
  const filteredItems = isWholeCoachingPurchased ? items : items.filter((item) => item !== VIDEO_TAB_MATERIAL);

  return (
    <article className={wrapper}>
      <div className={tabs}>
        {filteredItems.map((tab) => (
          <span
            key={nanoid()}
            className={tab === selectedIndex ? tabsActive : ''}
            onClick={() => setSelectedIndex(tab)}
          >
            {t(`video.details.page.tabs.${tab}`)}
          </span>
        ))}
      </div>
      {overview.includes(selectedIndex) && (
        <VideoCourseDescription
          isLesson={isLesson}
          longDescription={longDescription}
          coursePlan={coursePlan}
          description={description}
          type={type}
          eLearning={eLearning}
        />
      )}
      {VIDEO_TAB_NOTES === selectedIndex && <VideoTabNotes />}
      {VIDEO_TAB_MATERIAL === selectedIndex && <VideoTabDownloads downloads={downloads} />}
      {E_LEARNING_TAB_FAQ === selectedIndex && <VideoTabFaqs faqs={faqs} />}
    </article>
  );
}

export default CoachingVideoTabs;
